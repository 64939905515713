<template>
  <div class="dashboard-notifications">
    <profile-head
      :leftTitle="$t('order')"
      :title="$t('dashboard.requestInfo')"
    ></profile-head>
    <v-row
      class="mx-0"
      :class="i % 2 == 0 ? 'white' : 'bluebgdash radius-10'"
      v-for="(detail, i) in details"
      :key="i"
    >
      <v-col cols="6" md="4">
        <span class="font-16 greytext--text">
          {{ detail.key }}
        </span>
      </v-col>
      <v-col cols="6" md="8">
        <span class="font-16 greytext--text">
          {{ detail.value }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileHead from "../../../components/ProfileHead.vue";
export default {
  components: { ProfileHead },
  data: () => ({
    data: {},
    headers: [
      { text: "Name", value: "name", sortable: false, width: "85%" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    details: [],
  }),
  methods: {
    async getData() {
      let formData = new FormData();
      formData.append("order_id", this.$route.params.id);
      let { data } = await this.$axios.post("order/info", formData);

      if (data.success) {
        this.data = data.data[0];
        Object.keys(data.data[0]).forEach((detail) => {
          if (detail != "id" && detail != "created") {
            this.details.push({ key: detail, value: this.data[detail] });
          }
        });
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.dashboard-notifications {
  border-radius: 15px;
  background: white;
  padding: 8px;
  tbody tr:nth-child(even) {
    background-color: $bluebgdash;
  }
  thead {
    display: none;
  }
  td,
  th {
    border-bottom: none !important;
  }
  tr {
    height: 80px;
  }
  @media (max-width: 960px) {
    padding: 0px;
  }
}
</style>
